import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL || 'mrf-api.centivo.dev'
const s3Region = process.env.REACT_APP_S3_REGION || 'us-east-1'

const api = axios.create({
    baseURL: `https://${baseUrl}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

const formatFileUrl = (bucket, key, region) => {
    return `https://${bucket}.s3.${s3Region}.amazonaws.com/${key}`
}

const formatFileName = (key) => {
    const splitKey = key.split('/');
    return splitKey[splitKey.length - 1];
}

const formatFileSize = (size) => {
    if (size === 0) return '0 Bytes';

    const units = ['Bytes', 'KB', 'MB', 'GB'];
    const k = 1024;
    const i = Math.floor(Math.log(size) / Math.log(k));
    const formattedSize = (size / Math.pow(k, i)).toFixed(2);

    return `${formattedSize} ${units[i]}`;
}

const formatFileDate = (isodate) => {
    const date = new Date(isodate);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours24 >= 12 ? 'PM' : 'AM';

    return `${month} ${day}, ${year} ${hours12}:${minutes} ${ampm} `;
}

const formatFileList = (files, bucket) => {
    const fileList = [];
    for (const file of files) {
        fileList.push({
            name: formatFileName(file.Key),
            key: file.Key,
            size: formatFileSize(file.Size),
            lastModified: formatFileDate(file.LastModified),
            url: file.url || formatFileUrl(bucket, file.Key)
        })
    }
    return fileList;
}

const formatRedirectList = (redirects) => {
    const redirectList = [];
    for (const redirect of redirects) {
        redirectList.push({
            name: `${redirect.title}`,
            key: '',
            size: '-',
            lastModified: '-',
            url: redirect.url
        });
    }
    return redirectList;
}

const sortFileLIst = (files) => {
    return files.sort((a, b) => a.name.localeCompare(b.name));
} 

export const getFileList = async (clientId) => {
    try {
        const fileResponse = await api.get('/listFiles', {
            params: {
                clientId: clientId.toUpperCase()
            }
        });
        const fileList = formatFileList(fileResponse.data.Contents, fileResponse.data.Name);

        const redirectResponse = await api.get('/getRedirects', {
            params: {
                clientId: clientId.toUpperCase()
            }
        });
        const redirectList = formatRedirectList(redirectResponse.data)
        
        return sortFileLIst([...fileList, ...redirectList]);
    } catch (error) {
        console.error('Error retrieving file list:', error);
        throw error;
    }
};

export const getPresignedUrl = async (fileKey) => {
    // Unused since files are public
    try {
        const response = await api.get('/getFileUrl', {
            params: {
                key: decodeURI(fileKey)
            }
        });
        return response.data;

    } catch (error) {
        console.error('Error retrieving download URL:', error);
        throw error;
    }
};